.pricesTitle {
    display: flex;
    justify-content: center;
    font-size: large;
    font-weight: bold;
    padding: 5vh 0;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.pricesContainer, .pricesDescriptionContainer {
    display: flex;
    flex-direction: column;
    row-gap: 5vh;
    padding-bottom: 5vh;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.pricesItem, .pricesDescriptionItem {
    display: flex;
    flex-direction: column;
    row-gap: 1vh;
    padding: 2vh 5vw;
    border-top: solid 2px rgb(225, 225, 225);
    border-bottom: solid 2px rgb(225, 225, 225);
}

.pricePeriod, .pricesDescriptionTitle {
    font-weight: bold;
}

ul {
    margin: 0;
}

li {
    padding: 0.5vh 0;
}