.locationTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2vh;
    padding: 5vh 5vw;
    text-align: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.mapIcon {
}

.mapsContainer {
    display: flex;
    justify-content: center;
    padding: 0 5vw;
    padding-bottom: 5vh;
}

.mapsLocation {
    max-width: 100%;
    max-height: 100%;
    border: 0;
}

.locationInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    row-gap: 3vh;
    padding: 2vh 5vw;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.locationInfoItem {
    display: flex;
    flex-direction: column;
    row-gap: 0.5vh;
}

.locationInfoTitle {
    font-weight: bold;
}

.locationLinksContainer {
    padding: 2vh 5vw;
    padding-bottom: 7vh;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.locationLinks {
    display: flex;
    flex-direction: column;
    row-gap: 0.5vh;
}