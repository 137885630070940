.navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(1, 1, 77);
    border-bottom: solid 1px white;
}

.navigationItem {
    padding: 1.5vh 2vw;
    color: white;
    font-weight: bold;
}