.galleryTitle  {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    padding: 5vh 5vw;
}

.galleryPicsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 16vh;
    flex-direction: row;
    justify-content: center;
    column-gap: 3vw;
    row-gap: 3vh;
    padding: 0 4vw;
    padding-bottom: 10vh;
}

.galleryPic {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (min-width: 800px) {

    .galleryTitle {
        padding: 8vh 5vw;
    }

    .galleryPicsContainer {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-auto-rows: 32vh;
    }
}